/**
 * @generated SignedSource<<ab52c24b4e6560e164b3d184c456a549>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Faq_rankingCemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly name: string
  }>
  readonly total: number
  readonly ' $fragmentType': 'Faq_rankingCemeteries'
}
export type Faq_rankingCemeteries$key = {
  readonly ' $data'?: Faq_rankingCemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'Faq_rankingCemeteries'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Faq_rankingCemeteries',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Cemetery',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cemeteryId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        }
      ],
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = '241a626ef5f1681fc3f2d8723f607930'

export default node
