import type { FC, HTMLAttributes, PropsWithChildren } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'

const styles = {
  section: css({
    display: 'grid',
    gridTemplateColumns: '100%'
  }),
  heading: css({
    marginBottom: 8,
    [mq('sp')]: {
      paddingInline: 12
    }
  })
}

type SectionProps = PropsWithChildren<
  {
    title: string
  } & HTMLAttributes<HTMLElement>
>

export const Section: FC<SectionProps> = ({ title, children, ...rest }) => {
  return (
    <section css={styles.section} {...rest}>
      <h2 css={[styles.heading, typography.headingXS]}>{title}</h2>
      {children}
    </section>
  )
}
