import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { Accordion } from '@lifedot/atoms/Accordion'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = {
  section: css({
    background: palette.white,
    borderBottom: `1px solid ${palette.gray2}`,
    ':first-of-type': {
      borderTop: `1px solid ${palette.gray2}`
    },
    '[open]': {
      svg: {
        transform: 'rotate(180deg)'
      },
      h3: {
        fontWeight: 'bold'
      }
    }
  }),
  content: css({
    padding: '20px 40px',
    background: palette.background,
    [mq('sp')]: {
      padding: 12
    }
  }),
  title: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 40px',
    fontWeight: 'normal',
    [mq('sp')]: {
      padding: '8px 12px'
    }
  }),
  titleInner: css({
    flex: '1 1'
  })
}

export interface FaqAccordionProps {
  children: ReactNode
  title: ReactNode
  isOpen?: boolean
}

export const FaqAccordion: FC<FaqAccordionProps> = ({
  title,
  isOpen,
  children,
  ...props
}) => (
  <section
    css={styles.section}
    itemScope
    itemProp="mainEntity"
    itemType="https://schema.org/Question"
    {...props}
  >
    <Accordion
      isOpen={isOpen}
      title={
        <h3 css={[typography.textL, styles.title]} itemProp="name">
          <span css={styles.titleInner}>{title}</span>
          <ExpandMoreIcon />
        </h3>
      }
    >
      <div
        css={styles.content}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <p css={typography.textM} itemProp="text">
          {children}
        </p>
      </div>
    </Accordion>
  </section>
)
