import { FC } from 'react'
import { css } from '@emotion/react'
import { FaqAccordion } from '@lifedot/components/FaqAccordion'
import { router } from '@lifedot/router'
import { useFaqItem, useShouldDisplay } from './dependencies'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Faq_rankingCemeteries$key } from './__generated__/Faq_rankingCemeteries.graphql'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { FeatureCode, AttributeCode } from '@lifedot/constants/searchChoices'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  heading: css({
    marginBottom: 8,
    [mq('sp')]: {
      padding: '0 12px'
    }
  })
}

interface FaqProps {
  rankingCemeteries: Faq_rankingCemeteries$key
}

export const Faq: FC<FaqProps> = ({ rankingCemeteries }) => {
  const { items } = useFragment(rankingCemeteriesFragment, rankingCemeteries)
  const displayable = useShouldDisplay()
  const faqItem = useFaqItem(!displayable)
  if (!faqItem) return null

  const {
    popularFaqTitle,
    areaName,
    condition,
    city,
    station,
    prefectureName,
    prefectureRoma
  } = faqItem

  return (
    <section
      itemProp="text"
      itemScope
      itemType="https://schema.org/FAQPage"
      data-cy="Faq-section"
    >
      <h2 css={[styles.heading, typography.headingXS]}>よくある質問</h2>
      <EventTracker
        clone
        label="faq_人気のお墓"
        action="click"
        elementCategory={elementCategories.faq}
        elementLabel="人気のお墓"
      >
        <FaqAccordion title={popularFaqTitle}>
          <>
            {areaName}にある
            {condition?.name
              ? condition.code === FeatureCode.PET
                ? condition.name
                : `${condition.name}の`
              : null}
            お墓のうち、特に
            {items
              .slice(0, 3)
              .map(({ cemeteryId, name }) => (
                <a href={router.cemetery(cemeteryId)} key={cemeteryId}>
                  {name}
                </a>
              ))
              .reduce<React.ReactNode>(
                (acc, item) => (acc === null ? [item] : [acc, '、', item]),
                null
              )}
            が数多くの方に見られており、人気です。
            <br />
            <br />
            詳しくは、
            <a
              href={router.list({
                prefectureRoma,
                cityId: city?.cityId,
                stationId: station?.stationId,
                condition: condition ? condition : undefined
              })}
            >
              {areaName}の{condition?.name && `${condition.name}の`}
              お墓情報一覧
            </a>
            をご覧ください。
          </>
        </FaqAccordion>
      </EventTracker>
      <EventTracker
        clone
        label="faq_購入価格"
        action="click"
        elementCategory={elementCategories.faq}
        elementLabel="購入価格"
      >
        <FaqAccordion
          title={`${areaName}では、いくら位の価格でお墓を購入することができますか？`}
        >
          <>
            購入費用は、一般墓（墓石のお墓）、樹木葬、納骨堂、永代供養墓など、お墓の種類によってそれぞれ相場が異なります。
            <br />
            各お墓タイプごとの詳しい相場は、
            <a
              href={
                router.list({
                  prefectureRoma,
                  cityId: city?.cityId
                }) + '#averagePrice'
              }
            >
              {areaName}
              のお墓の平均費用
            </a>
            をご覧ください。
            <br />
            ご予算がお決まりでしたら、数あるお墓情報の中から価格帯を指定して検索をすることもできます。
            <br />
            <br />
            また、安い予算でお墓をお考えの場合は、
            <a
              href={router.list({
                prefectureRoma,
                condition: {
                  attributeCode: AttributeCode.FEATURE,
                  code: FeatureCode.SUCCESSOR_EXISTENCE
                }
              })}
            >
              {prefectureName}の永代供養プランありのお墓情報一覧
            </a>
            から、費用を抑えたお墓を探すのもおすすめです。
          </>
        </FaqAccordion>
      </EventTracker>
      <EventTracker
        clone
        label="faq_自宅近くのお墓"
        action="click"
        elementCategory={elementCategories.faq}
        elementLabel="自宅近くのお墓"
      >
        <FaqAccordion title={`${areaName}の自宅近くにあるお墓を探せますか？`}>
          <>
            地図上から{areaName}にあるお墓を選ぶことができます。
            <br />
            <br />
            お墓探しにおいて、立地はとても重要です。
            <br />
            お墓参りに通うことを見越して、ご自宅近くの立地や、交通アクセスのよい場所で探される方が多いです。
          </>
        </FaqAccordion>
      </EventTracker>
    </section>
  )
}

const rankingCemeteriesFragment = graphql`
  fragment Faq_rankingCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      name
    }
    total
  }
`
