import { FC } from 'react'
import { FaqAccordion } from '@lifedot/components/FaqAccordion'
import { router } from '@lifedot/router'
import { useFaqItem, useShouldDisplay } from './dependencies'
import { FeatureCode, AttributeCode } from '@lifedot/constants/searchChoices'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { Contents } from './contents'
import { markup } from '@lifedot/utils'
import { Section } from '@/components/Section/Section'

export type AreaFaqProps = {
  contents: Contents
}

export const AreaFaq: FC<AreaFaqProps> = ({ contents }) => {
  const displayable = useShouldDisplay()
  const faqItem = useFaqItem(!displayable)
  if (!faqItem) return null

  const { areaName, city, prefectureName, prefectureRoma } = faqItem

  return (
    <Section
      title={`${areaName}のお墓に関するよくある質問`}
      itemProp="text"
      itemScope
      itemType="https://schema.org/FAQPage"
      data-cy="Faq-section"
    >
      <EventTracker
        clone
        label="faq_購入価格"
        action="click"
        elementCategory={elementCategories.faq}
        elementLabel="購入価格"
      >
        <FaqAccordion
          title={`${areaName}のお墓の購入費用の相場はいくらですか？`}
          isOpen
        >
          <>
            購入費用は、一般墓（墓石のお墓）、樹木葬、納骨堂、永代供養墓など、お墓の種類によってそれぞれ相場が異なります。
            <br />
            各お墓タイプごとの詳しい相場は、
            <a
              href={
                router.list({
                  prefectureRoma,
                  cityId: city?.cityId
                }) + '#averagePrice'
              }
            >
              {areaName}
              のお墓の平均費用
            </a>
            をご覧ください。
            <br />
            ご予算がお決まりでしたら、数あるお墓情報の中から価格帯を指定して検索をすることもできます。
            <br />
            <br />
            また、安い予算でお墓をお考えの場合は、
            <a
              href={router.list({
                prefectureRoma,
                condition: {
                  attributeCode: AttributeCode.FEATURE,
                  code: FeatureCode.SUCCESSOR_EXISTENCE
                }
              })}
            >
              {prefectureName}の永代供養プランありのお墓情報一覧
            </a>
            から、費用を抑えたお墓を探すのもおすすめです。
          </>
        </FaqAccordion>
      </EventTracker>

      <FaqAccordion isOpen title={`${areaName}に樹木葬はありますか？`}>
        <span dangerouslySetInnerHTML={markup(contents[4]?.answer as string)} />
      </FaqAccordion>

      <FaqAccordion
        isOpen
        title={`${areaName}にある有名な公営霊園を教えてください`}
      >
        <span dangerouslySetInnerHTML={markup(contents[3]?.answer as string)} />
      </FaqAccordion>

      <EventTracker
        clone
        label="faq_自宅近くのお墓"
        action="click"
        elementCategory={elementCategories.faq}
        elementLabel="自宅近くのお墓"
      >
        <FaqAccordion
          isOpen
          title={`${areaName}の自宅近くにあるお墓を探せますか？`}
        >
          <>
            地図上から{areaName}にあるお墓を選ぶことができます。
            <br />
            <br />
            お墓探しにおいて、立地はとても重要です。
            <br />
            お墓参りに通うことを見越して、ご自宅近くの立地や、交通アクセスのよい場所で探される方が多いです。
          </>
        </FaqAccordion>
      </EventTracker>

      <FaqAccordion
        isOpen
        title={`${areaName}でお墓・墓地の多いエリアはどこですか？`}
      >
        <span dangerouslySetInnerHTML={markup(contents[2]?.answer as string)} />
      </FaqAccordion>
    </Section>
  )
}
