import {
  AttributeCode,
  CemeteryTypeCode,
  FacilityCode,
  FeatureCode,
  SectCode
} from '@lifedot/constants/searchChoices'
import { useListPage } from '@/hooks/use-list-page'
import { ListPageContextProvider_station$data } from '@/hooks/use-list-page/__generated__/ListPageContextProvider_station.graphql'
import { ListPageContextProvider_city$data } from '@/hooks/use-list-page/__generated__/ListPageContextProvider_city.graphql'

const codes = [
  FacilityCode.BARRIER_FREE,
  FeatureCode.PET,
  FeatureCode.SUCCESSOR_EXISTENCE,
  CemeteryTypeCode.KOEI,
  CemeteryTypeCode.MINEI,
  CemeteryTypeCode.TEMPLE
].map((v) => v.toString())

export const useShouldDisplay = (): boolean => {
  const {
    searchParams,
    pagination: { currentPageNumber }
  } = useListPage()

  const { cityIds, stationIds, conditions } = searchParams

  if (currentPageNumber > 1) return false
  if (cityIds && cityIds.length > 1) return false
  if (stationIds && stationIds.length > 1) return false
  if (conditions.length > 1) return false

  const condition = conditions[0]

  if (!condition) return true

  const { attributeCode, code } = condition

  if (attributeCode === AttributeCode.SECTION_TYPE) return true
  if (attributeCode === AttributeCode.SECT && code !== SectCode.OTHER)
    return true

  return codes.includes(code)
}

export const useFaqItem = (
  skip: boolean
): {
  popularFaqTitle: string
  areaName: string
  condition: ReturnType<typeof useListPage>['condition']
  city: ListPageContextProvider_city$data | null
  station: ListPageContextProvider_station$data | null
  prefectureName: string
  prefectureRoma: string
} | null => {
  const { prefecture, city, condition, station } = useListPage()
  if (skip) return null
  const { name: prefectureName, roma: prefectureRoma } = prefecture

  const areaName = city
    ? city.name
    : station
    ? `${station.name}駅周辺`
    : prefectureName

  const popularFaqTitle = condition
    ? condition.code === FeatureCode.PET
      ? `${areaName}の${condition.name}人気のあるお墓はどこですか？`
      : `${areaName}の${condition.name}で、人気のあるお墓はどこですか？`
    : `${areaName}で、人気のあるお墓はどこですか？`

  return {
    popularFaqTitle,
    areaName,
    condition,
    city,
    station,
    prefectureName,
    prefectureRoma
  }
}
